import $ from 'jquery';
import 'owl.carousel';
import './jquery.selectric.min.js';
import validate from 'jquery-validation';

function initPartnerIdReader() {
  const url = new URL(window.location.href);

  if (url.searchParams.get('label_id')) {
    let date = new Date();
    date.setMonth(date.getMonth() + 2);
    document.cookie =
      'label_id=' +
      url.searchParams.get('label_id') +
      '; expires=' +
      date.toUTCString();
  }
}

function setCookie(name, value, options) {
  options = options || {};

  var expires = options.expires;

  if (typeof expires == 'number' && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  var updatedCookie = name + '=' + value;

  for (var propName in options) {
    updatedCookie += '; ' + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }

  document.cookie = updatedCookie;
}

function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
      name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
      '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function validateEmail(email) {
  var re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}

$(document).ready(function () {
  const YA_CLID = 52970623;
  var $popup = $('.popoup');
  var spinnerData =
    '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Отправка...';

  function closePopup() {
    $popup.removeClass('open');
  }

  function openPopup(id = false) {
    var popup = $('.popoup');
    if (id) {
      popup = $('#' + id);
    }
    popup.addClass('open');
  }

  function deactivateBtn(btn) {
    btn.attr('disabled', true);
    btn.html(spinnerData);
  }

  function activateBtn(btn, btnText) {
    console.log(btn);
    console.log(btnText);
    btn.attr('disabled', false);
    btn.text(btnText);
  }

  // add label_id to cookie
  initPartnerIdReader();

  // init all selectrics

  $('select').selectric({
    maxHeight: 200,
    onChange: function (element) {
      $(element).change();
      if ($(element).hasClass('pop_calc_select')) {
        $('#calc_sale > span, #calc_price > span').text('-');
        var job = $(element).find('option:selected').attr('data-job');
        $('#calc_step').attr('disabled', false);
        console.log(job);
        if (typeof job !== 'undefined') {
          $.ajax({
            type: 'POST',
            url: '/wp-json/crm/v1/getJobInfo/',
            data: {
              tag: job,
            },
            success: function (res) {
              console.log(res);
              console.log(typeof res.fields);
              console.log(
                res.fields &&
                typeof res.fields !== 'undefined' &&
                res.fields.tpw_price &&
                typeof res.fields.tpw_price !== 'undefined'
              );
              if (typeof res.fields !== 'undefined' && res.fields.tpw_price) {
                var sale = parseInt(res.fields.tpw_price) * 0.3;
                sale = Math.round(sale / 100) * 100;
                $('#calc_price > span').text(parseInt(res.fields.tpw_price));
                $('#calc_sale > span').text(parseInt(sale));
              }
            },
            error: function (xhr, status, error) {
              var err = JSON.parse(xhr.responseText);
              console.log(err);
            },
          });
        }
      }
    },
  });

  // init calculator popup
  $('#calc_step').on('click', function () {
    $('#calc_email').show();
    $(this).hide();
    $('#calc_result').show();
    $('#calc_submit').show();
  });

  $('.js-popup-open').on('click', function () {
    var id = false;
    id = $(this).attr('data-popup');
    openPopup(id);
  });

  $('.js-popup-close').on('click', function () {
    closePopup();
  });

  // init slider
  var $topslider = $('.js-carousel');

  $topslider.owlCarousel({
    loop: false,
    margin: 0,
    autoplay: false,
    nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      768: {
        items: 2,
        slideBy: 2,
      },
      1200: {
        items: 3,
        slideBy: 3,
      },
    },
  });

  $('.js-top-slider-dot').on('click', function () {
    var index = $(this).index();

    $topslider.trigger('to.owl.carousel', [index, 300]);
  });

  // init widgets
  $(document).on('focus', 'input', function (e) {
    setCookie('showidget2', time(), { path: '/' });
    setCookie('showidget', time(), { path: '/' });
  });

  // init forms
  const themeInput = $('#top-panel-form-theme');
  const emailBox = $('.js-top-panel-form-emailcontainer');
  const emailInput = $('#top-panel-form-email');
  const emailInputFooter = $('.registration-box__form-input');
  const btn = $('.js-top-panel-form-btn');
  const btnFooter = $('.registration-box__form-btn');

  btnFooter.on('click', function (e) {
    e.preventDefault();
    const valid = validateEmail($(emailInputFooter).val());
    const btnText = $(this).text();
    if (!valid) {
      emailInputFooter.addClass('error');
      emailInputFooter.parent().find('.error-text').removeClass('hidden');
      return false;
    }
    if (valid) {
      deactivateBtn($(this));
      var button = $(this);
      var dataForm = $(this).parents('form:first').serializeArray();
      if (getCookie('label_id')) {
        dataForm.push({ name: 'label_id', value: getCookie('label_id') });
      }
      $.ajax({
        type: 'POST',
        url: '/wp-json/crm/v1/createUser/',
        data: $.param(dataForm),
        success: function (res) {
          var url;
          var labelIdQuery = getCookie('label_id');
          ym(YA_CLID, 'reachGoal', 'ORDER');
          if (res.authlink) {
            url = new URL(res.authlink + '/firstOrder/');
          } else {
            url = new URL('https://studservis-lk.ru/orders/edit/');
          }

          res.selected_work ?
            url.searchParams.set('tpw', res.selected_work) : null;
          res.theme ?
            url.searchParams.set('theme', res.theme) : null;
          labelIdQuery ? url.searchParams.set('label_id', labelIdQuery) : null;
          console.log(url.toString());
          activateBtn(button, btnText);
          openPopup('success');
        },
        error: function () {
          activateBtn($(this), btnText);
          alert('Ошибка отправки.');
        },
      });
    }
  });

  if (!!themeInput.length) {
    btn.on('click', function (e) {
      e.preventDefault();
      const valid = validateEmail($(emailInput).val());
      const btnText = $(this).text();
      if (
        themeInput.val().length &&
        emailBox.hasClass('top-panel__form-hidden')
      ) {
        $('.top-panel-bg').addClass('active');
        emailBox.removeClass('top-panel__form-hidden');

        return false;
      }

      if (!themeInput.val().length) {
        themeInput.addClass('error');
        themeInput.parent().find('.error-text').removeClass('hidden');

        return false;
      }

      if (!valid) {
        emailInput.addClass('error');
        emailInput.parent().find('.error-text').removeClass('hidden');
        return false;
      }
      if (valid) {
        deactivateBtn($(this));
        var button = $(this);
        var dataForm = $(this).parents('form:first').serializeArray();
        if (getCookie('label_id')) {
          dataForm.push({ name: 'label_id', value: getCookie('label_id') });
        }
        $.ajax({
          type: 'POST',
          url: '/wp-json/crm/v1/createUser/',
          data: $.param(dataForm),
          success: function (res) {
            var url;
            var labelIdQuery = getCookie('label_id');
            ym(YA_CLID, 'reachGoal', 'ORDER');
            if (res.authlink) {
              url = new URL(res.authlink + '/firstOrder/');
            } else {
              url = new URL('https://studservis-lk.ru/orders/edit/');
            }

            res.selected_work ?
              url.searchParams.set('tpw', res.selected_work) : null;
            res.theme ?
              url.searchParams.set('theme', res.theme) : null;
            labelIdQuery ? url.searchParams.set('label_id', labelIdQuery) : null;
            console.log(url.toString());
            activateBtn(button, btnText);
            openPopup('success');
          },
          /*           type: 'POST',
                    url: '/wp-json/crm/v1/createOrder/',
                    data: $.param(dataForm),
                    success: function (res) {
                      ym(YA_CLID, 'reachGoal', 'ORDER');
                      if (typeof res.link !== 'undefined') {
                        window.location.href =
                          res.link + '/newOrderId=' + res.order_id + '/';
                      } else {
                        window.location.href = 'https://studservis-lk.ru/orders/edit/';
                      }
                    }, */
          error: function () {
            activateBtn($(this), btnText);
            alert('Ошибка отправки.');
          },
        });
      }
    });

    themeInput.on('keyup', function () {
      if (themeInput.hasClass('error')) {
        themeInput.removeClass('error');
        themeInput.parent().find('.error-text').addClass('hidden');
      }
    });

    emailInput.on('keyup', function () {
      if (emailInput.hasClass('error')) {
        emailInput.removeClass('error');
        emailInput.parent().find('.error-text').addClass('hidden');
      }
    });
  }

  if (!themeInput.length) {
    emailInput.on('keyup', function () {
      const valid = validateEmail($(this).val());

      if (emailInput.hasClass('error')) {
        emailInput.removeClass('error');
        emailInput.parent().find('.error-text').addClass('hidden');
      }

      if (!!valid) $('.top-panel-bg').addClass('active');
    });

    btn.on('click', function (e) {
      e.preventDefault();
      const valid = validateEmail($(emailInput).val());
      const btnText = $(this).text();

      if (!valid) {
        emailInput.addClass('error');
        emailInput.parent().find('.error-text').removeClass('hidden');
        return false;
      }
      if (valid) {
        deactivateBtn($(this));
        var button = $(this);
        var dataForm = $(this).parents('form:first').serializeArray();
        if (getCookie('label_id')) {
          dataForm.push({ name: 'label_id', value: getCookie('label_id') });
        }
        $.ajax({
          type: 'POST',
          url: '/wp-json/crm/v1/createUser/',
          data: $.param(dataForm),
          success: function (res) {
            var url;
            var labelIdQuery = getCookie('label_id');
            ym(YA_CLID, 'reachGoal', 'ORDER');
            if (res.authlink) {
              url = new URL(res.authlink + '/firstOrder/');
            } else {
              url = new URL('https://studservis-lk.ru/orders/edit/');
            }

            res.selected_work ?
              url.searchParams.set('tpw', res.selected_work) : null;
            res.theme ?
              url.searchParams.set('theme', res.theme) : null;
            labelIdQuery ? url.searchParams.set('label_id', labelIdQuery) : null;
            console.log(url.toString());
            activateBtn(button, btnText);
            openPopup('success');
          },
          error: function () {
            activateBtn($(this), btnText);
            alert('Ошибка отправки.');
          },
        });
      }
    });
  }

  $('.validate-form').each(function () {
    $(this).validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
        theme: {
          required: true,
        },
        /*         type_of_work: {
                  required: true,
                }, */
      },
      messages: {
        email: {
          required: 'Поле e-mail обязательно для заполнения',
          email: 'Введите корректный e-mail',
        },
        theme: {
          required: 'Поле "Тема работы" обязательно для заполнения',
        },
        /*         type_of_work: {
                  required: 'Поле "Тип работы" обязательно для заполнения',
                }, */
      },
      submitHandler: function (form) {
        // some other code
        // maybe disabling submit button
        // then:
        var submitBtn = $(form).find('button[type="submit"]');
        var submitBtnText = submitBtn.text();
        deactivateBtn(submitBtn);
        var dataForm = $(form).serializeArray();
        if (getCookie('label_id')) {
          dataForm.push({ name: 'label_id', value: getCookie('label_id') });
        }
        $.ajax({
          type: 'POST',
          url: '/wp-json/crm/v1/createUser/',
          data: $(form).serialize(),
          success: function (res) {
            var url;
            ym(YA_CLID, 'reachGoal', 'ORDER');
            if (res.authlink) {
              url = new URL(res.authlink + '/firstOrder/');
            } else {
              url = new URL('https://studservis-lk.ru/orders/edit/');
            }
            res.selected_work ?
              url.searchParams.set('tpw', res.selected_work) : null;
            res.theme ?
              url.searchParams.set('theme', res.theme) : null;
            activateBtn(submitBtn, submitBtnText);
            openPopup('success');
          },
          error: function () {
            activateBtn(submitBtn, submitBtnText);
            alert('Ошибка отправки.');
          },
        });
      },
    });
  });
});
